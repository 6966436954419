// import React, { useState, useEffect } from 'react';
// import packageJson from '../package.json';

// global.appVersion = packageJson.version;

// const semverGreaterThan = (versionA, versionB) => {
//   const versionsA = versionA.split('.');
//   const versionsB = versionB.split('.');

//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());
//     const b = Number(versionsB.shift());

//     if (a === b) continue;
//     return a > b || isNaN(b);
//   }

//   return false;
// };

// const CacheBuster = ({ children }) => {
//   const [loading, setLoading] = useState(true);
//   const [isLatestVersion, setIsLatestVersion] = useState(false);

//   const refreshCacheAndReload = () => {
//     console.log('Clearing cache and hard reloading...');
//     if (caches) {
//       caches.keys().then(names => {
//         names.forEach(name => caches.delete(name));
//       });
//     }

//     if ('serviceWorker' in navigator) {
//       navigator.serviceWorker.getRegistrations().then(registrations => {
//         registrations.forEach(registration => registration.unregister());
//       });
//     }

//     // window.location.reload();
//     window.location.href = '/';
//   };

//   useEffect(() => {
//     fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
//       .then(response => response.json())
//       .then(meta => {
//         const latestVersion = meta.version;
//         const currentVersion = global.appVersion;

//         console.log(`Meta version: ${latestVersion}, App version: ${currentVersion}`);

//         const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
//         if (shouldForceRefresh) {
//           console.log(`New version available: ${latestVersion}`);
//           setLoading(false);
//           setIsLatestVersion(false);
//         } else {
//           console.log(`App is up-to-date: ${latestVersion}`);
//           setLoading(false);
//           setIsLatestVersion(true);
//         }
//       })
//       .catch(error => {
//         console.error('Failed to fetch meta.json:', error);
//         setLoading(false); // Avoid indefinite loading state
//       });
//   }, []);

//   return children({ loading, isLatestVersion, refreshCacheAndReload });
// };

// export default CacheBuster;

import React, { useState, useEffect } from "react";
import packageJson from "../package.json";

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(".");
  const versionsB = versionB.split(".");

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) continue;
    return a > b || isNaN(b);
  }

  return false;
};

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  const refreshCacheAndReload = async () => {
    console.log("Clearing cache and redirecting to login...");

    if (caches) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((name) => caches.delete(name)));
    }

    if ("serviceWorker" in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(
        registrations.map((registration) => registration.unregister())
      );
    }

    // Set a flag to prevent repeated redirects
    sessionStorage.setItem("cacheRefreshed", "true");

    // Redirect to the login page
    window.location.href = "/";
  };

  useEffect(() => {
    const alreadyRefreshed = sessionStorage.getItem("cacheRefreshed");
    if (alreadyRefreshed) {
      setLoading(false);
      setIsLatestVersion(true);
      return; // Prevent repeated checks if already refreshed
    }

    fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        console.log(
          `Meta version: ${latestVersion}, App version: ${currentVersion}`
        );

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(`New version available: ${latestVersion}`);
          setLoading(false);
          setIsLatestVersion(false);
        } else {
          console.log(`App is up-to-date: ${latestVersion}`);
          setLoading(false);
          setIsLatestVersion(true);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch meta.json:", error);
        setLoading(false); // Avoid indefinite loading state
      });
  }, []);

  return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
